import request from '@/utils/request';

//列表
export function companyWasteList(data) {
  return request({
    url : '/companyWaste/companyWasteList',
    method : 'post',
    data : data
  })
}

//新增
export function createCompanyWaste(data) {
  return request({
    url : '/companyWaste/createCompanyWaste',
    method : 'post',
    data : data
  })
}
//修改
export function updateCompanyWaste(data) {
  return request({
    url :'/companyWaste/updateCompanyWaste',
    method : 'post',
    data : data
  })
}


//删除
export function deleteCompanyWaste(data) {
  return request({
    url : '/companyWaste/deleteCompanyWaste',
    method : 'post',
    data : data
  })
}
//批量删除
export function batchDeleteCompanyWaste(data) {
  return request({
    url : '/companyWaste/batchDeleteCompanyWaste',
    method : 'post',
    data : data
  })
}
